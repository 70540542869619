export const data = {
  "$^AboutUs^$": "Über uns",
  "$^CATEGORY^$": "Kategorie",
  "$^ContactUs^$": "Kontaktiere uns",
  "$^Country^$": "Land",
  "$^FindAStoreBuyNow^$": "Händlersuche",
  "$^Menu^$": "Menü",
  "$^Newsroom^$": "Newsroom",
  "$^Product-Pro^$": "Exklusiv für Pro-Mitglieder",
  "$^ProductCare^$": "Produktpflege",
  "$^ProductService^$": "Produktservice",
  "$^ProfessionalUse^$": "Arc'teryx PRO",
  "$^Professional^$": "$^Professional^$",
  "$^PromoHolidayShippingAlert^$": "WEIHNACHTSVERSAND BIS ZUM 17. DEZEMBER",
  "$^SignUpBodyCopy2^$":
    "Meldet euch an, um Informationen über neue Produkte, Rabattcodes und Einladungen zu Events zu erhalten und an unseren Verlosungen teilzunehmen.",
  "$^SignUpCta2^$": "E-MAILS ERHALTEN",
  "$^SignUpTitle^$": "Zu den ersten gehören, die wir informieren",
  "$^System-A^$": "$^System-A^$",
  "$^Veilance^$": "$^Veilance^$",
  "$^arc-naming-scheme^$": "Arc'teryx-Benennungsschema",
  "$^climbing-lookbook^$": "Lookbook Klettern",
  "$^community-events^$": "Community Events",
  "$^explore^$": "Mehr Entdecken",
  "$^gift-guide^$": "Geschenkideen",
  "$^filterBar-collection^$": "Kollektion",
  "$^footer-beFirstToKnow^$": "Erfahren Sie Neuigkeiten zuerst",
  "$^footer-findArcStore^$": "Arc’teryx Verkaufspunkte Finden",
  "$^footer-followUs-header^$": "Folgen Sie uns",
  "$^footer-locateBrandStoreOrPartner^$":
    "Einen Brandstore, Partnerhändler oder Einzelhandelspartner in eurer Nähe finden.",
  "$^footer-nav-campaign-and-events^$": "Kampagnen & Events",
  "$^footer-nav-careers^$": "Karriere",
  "$^footer-nav-toTop^$": "Nach oben",
  "$^footer-newMoreInfoHelp^$":
    "Benötigt ihr weitere Informationen? Möchtet ihr einen Artikel reparieren lassen? Kein Problem. Wir helfen euch gern.",
  "$^footer-pro-program^$": "Pro-Programm",
  "$^footer-recall-info^$": "Rückrufinformationen",
  "$^footer-sitemap^$": "Sitemap",
  "$^freeShippingAndReturnsMsgJP^$": "Alle Bestellungen versandkostenfrei - kostenlose Rücksendung innerhalb 30 Tagen",
  "$^nav-CommuterBags^$": "Taschen für Pendler",
  "$^nav-IntendedUse^$": "Verwendungszweck",
  "$^nav-ShopMore^$": "Weiter einkaufen",
  "$^nav-arcAcademy^$": "Arc'teryx Academy",
  "$^nav-arcFavourites^$": "Arc’ Favoriten",
  "$^nav-avalancheAirbags^$": "Lawinen-Airbags",
  "$^nav-birdBlog^$": "Bird Blog",
  "$^nav-boots^$": "Bergschuhe",
  "$^nav-chalkBags^$": "Chalkbags",
  "$^nav-daypacks^$": "Tagesrucksäcke",
  "$^nav-gift-guide^$": "Geschenkideen",
  "$^nav-duffleBagsAndTotes^$": "Duffle Bags & Taschen",
  "$^nav-fallLookbook^$": "Lookbook Herbst",
  "$^nav-find-answers^$": "Antworten finden",
  "$^nav-giftCards^$": "Geschenkgutscheine",
  "$^nav-gifts-for-him^$": "Geschenke Für Ihn",
  "$^nav-gloves^$": "Handschuhe",
  "$^nav-guidePicks^$": "Produktauswahl Unserer Guides",
  "$^nav-harnesses^$": "Klettergurte",
  "$^nav-hatsAndCaps^$": "Mützen & Caps",
  "$^nav-hikingAndTrekking^$": "Wandern & Trekking",
  "$^nav-hydration-vests^$": "Trinkweste",
  "$^nav-layering-guide^$": "Layering Guide",
  "$^nav-midLayerAndFleece^$": "Mittellagen & Fleece",
  "$^nav-midLayer^$": "Isolationsschicht",
  "$^nav-more^$": "Mehr ...",
  "$^nav-multiDay^$": "Mehrtagestouren",
  "$^nav-ourStory^$": "Unsere Geschichte",
  "$^nav-problemSolvers^$": "Die Problemlöser",
  "$^nav-ropeBags^$": "Seilsäcke",
  "$^nav-shellFinder^$": "Jacken-Finder",
  "$^nav-footwearFinder^$": "Schuh-Finder",
  "$^nav-shoes^$": "Schuhe",
  "$^nav-skiAndSnowboard^$": "Skifahren & Snowboarden",
  "$^nav-springLookbook^$": "Lookbook Frühjahr",
  "$^nav-sustainability^$": "Nachhaltigkeit",
  "$^nav-toquesAndBeanies^$": "Toques & Beanies",
  "$^nav-trail-running^$": "Trailrunning",
  "$^nav-travelAndCommute^$": "Reisen & Pendeln",
  "$^nav-travelBags^$": "Reisetaschen",
  "$^nav-travelCollection^$": "Reise Kollektion",
  "$^nav-veilance^$": "Veilance",
  "$^nav-whoweare^$": "Unsere DNA",
  "$^nav-winterLookbook^$": "Winter Lookbook",
  "$^telephoneAssistanceES^$": "$^telephoneAssistanceES^$",
  "$^telephoneAssistanceIT^$": "$^telephoneAssistanceIT^$",
  "$^whatsNew^$": "Was ist neu",
  "10 Years": "Zehn Jahre",
  "About Us": "Über uns",
  Accessibility: "Accessibility",
  Accessories: "Accessoires",
  "Alpine & Rock Climbing": "Alpinismus & Klettern",
  "Android App": "Android App",
  "Arc'teryx Limited Warranty": "Beschränkte Arc'teryx Garantie",
  "Arc'teryx Name": "Arc'teryx",
  "Arc'teryx Outlet FAQ": "Arc'teryx Outlet FAQs",
  "Arc'type": "Arc'type",
  "Athletes & Ambassadors": "Athleten & Markenbotschafter",
  "Base Layer": "Funktionswäsche",
  Bottoms: "Hosen",
  Brand: "Marke",
  "CA Supply Chains Act": "CA Supply Chains Act",
  Checkout: "Zur Kasse",
  Climate: "Klima",
  "Climbing Gear": "Kletterausrüstung",
  Clothing: "Bekleidung",
  Collection: "KOLLEKTION",
  "Cookie Preference Centre": "Cookie-Einstellungscenter",
  "Cookie Policy": "Cookies-Richtlinie",
  "Customer Support Centre": "Kunden service zentrum",
  "Do Not Sell My Personal Information": "Meine personenbezogenen Daten dürfen nicht verkauft werden",
  Editorial: "Redaktionelles",
  "Email List": "Mailingliste",
  Everyday: "Everyday",
  Exclusives: "Wieder auf Lager",
  "FREE RETURNS": "KOSTENFREIE RÜCKGABE",
  "FREE SHIPPING | FREE RETURNS": "KOSTENLOSER VERSAND / KOSTENLOSE RÜCKNAHME",
  "Fall/Winter 2020": "Herbst/Winter 2020",
  "Fall/Winter 2021": "Herbst/Winter 2021",
  "Fast and Light": "Fast and Light",
  Favourites: "Favoriten",
  Fleece: "Fleece",
  "Climbing-Gear": "Kletterausrüstung",
  Footwear: "Schuhe",
  "Free 2 Day Shipping": "Kostenloser Expressversand",
  "Free Returns": "KOSTENFREIE RÜCKGABE",
  "Freedom of Movement": "Unglaubliche Bewegungsfreiheit",
  "Hot/Humid": "Heiß/Feucht",
  "Insulated Jackets": "Isolierte Jacken",
  Jackets: "Jacken",
  "Join Our Mailing List": "Registrieren Sie sich in unserer Mailingliste",
  "Just Added": "Neue Produkte",
  "Learn More": "Mehr Erfahren",
  "Leather Casing Goods": "Lederetuis",
  Mens: "Herren",
  "My Account": "Mein Kundenkonto",
  "My Profile": "Mein Profil",
  "New Arrivals": "Neue Produkte",
  "Beta Family": "Beta Familie",
  "Gamma Collection": "Gamma Kollektion",
  "Sun-Protection Collection": "Sonnenschutz-Kollektion",
  "Athlete Picks": "Athlete Picks",
  "Online Exclusives": "Online Exclusives",
  "Order Tracking": "Paketverfolgung",
  "Outlet Name": "Outlet",
  Packs: "Rucksäcke",
  Pants: "Hosen",
  "Past, Present, and Future": "Vergangenheit, Gegenwart, und Zukunft",
  "Performance Denim": "Performance Denim",
  "Privacy Policy": "Datenschutz",
  "Problem Solvers": "Die Erfinder von heute",
  "Procline Boots Safety Recall": "Freiwillige Rückrufaktion für Procline",
  "Procline Voluntary Recall": "Arc'teryx ruft momentan sämtliche Modelle der Procline Boots zurück",
  "Product Care": "Produktpflege",
  "Product Service": "Produktservice",
  "Professional Use": "Arc'teryx PRO",
  Rain: "Regen",
  "Re-System": "Re-System",
  "Re-System Name": "Re-System",
  "Returns & Refunds": "Rückgabe & Rückerstattung",
  Search: "suchen",
  "Severe Cold": "Extreme Kälte",
  "Shell Jackets": "Shell-Jacken",
  "Just Landed": "Gerade gelandet",
  "Shipping & Delivery": "Versand & Lieferung",
  "Shirts & Tops": "Shirts & Tops",
  "Shirts & Tanks": "T-Shirts & Tanktops",
  Shop: "Zum Shop",
  "Shop Arc'teryx": "Arc'teryx shoppen",
  "Shop Outlet": "Im Outlet shoppen",
  "Shop Used Gear": "Used Gear shoppen",
  Shorts: "Shorts",
  "Sign Out": "Abmelden",
  "Sign Up for Emails": "Newsletter abonnieren",
  "Sign-In": "Anmelden",
  "Sign-Out": "Sign-Out",
  "Sizing & Fit Guide": "Größentabellen",
  "Ski & Snowboard Collection": "Bestseller Outerwear",
  "Dresses & Skirts": "Kleider & Röcke",
  Socks: "Socken",
  "Specified Commercial Transaction Act": "Specified Commercial Transaction Act",
  "Spring/Summer 2020": "Spring/Summer 2020",
  "Spring/Summer 2021": "Frühling/Sommer 2021",
  "Spring/Summer 2022": "Frühling/Sommer 2022",
  "Stay updated with brand and product news.": "Auf dem Laufenden bleiben. Mit unseren Marken- und Produktnews.",
  "Student Discount": "Studentenrabatt",
  "System_A Name": "System_A",
  "Terms & Conditions": "Geschäftsbedingungen",
  "Terms of Use": "Nutzungsbedingungen",
  Tops: "Oberteile",
  "Used Gear Name": "Used Gear",
  REGEAR: "REGEAR",
  "Veilance Name": "Veilance",
  "Veilance Womens": "Veilance Womens",
  "Veilance X Chito": "Veilance X Chito",
  "Veilance is a brand of Arc'teryx Equipment arcteryx.com":
    'Veilance ist eine Marke von Arc\'teryx Equipment <a href="https://arcteryx.com">arcteryx.com</a>',
  "General FAQ": "FAQ – häufige fragen",
  Wind: "Wind",
  Womens: "Damen",
  "free Shipping And Returns Msg JP": "Alle Bestellungen versandkostenfrei - kostenlose Rücksendung innerhalb 30 Tagen",
  "iOS App": "iOS App",
  "WCAG Statement":
    "Arc’teryx is committed to achieving Level AA conformance for this website in conformance with the Web Content Accessibility Guidelines (WCAG) 2.0 and achieving compliance with other accessibility standards. Please contact Customer Service at +1 866 458 2473 (toll free), if you have any issues accessing information on this website.",
  "Obsessive Design": "Obsessive Design",
  "Product Advice": "Arc'teryx Advice",
  "Black Friday Sale": "Black Friday Sale",
  Sale: "Sale",
  "Women's Black Friday Sale": "Black Friday Sale Damen",
  "Men's Black Friday Sale": "Black Friday Sale Herren",
  "Colour Collection": "Colour Kollektion",
  "What's Your Warmest": "Die wärmsten Produkte",
  "For Him": "Für ihn",
  "Gifts for Him": "Geschenke für ihn",
  "Best Sellers": "Bestseller",
  "Gifts Under $50": "Geschenke unter $ 50",
  "Gifts Under £50": "Geschenke unter £ 50",
  "Gifts Under KR 500": "Geschenke unter KR 500",
  "Gifts Under NOK 500": "Geschenke unter NOK 500",
  "Gifts Under PLN 200": "Geschenke unter PLN 200",
  "Gifts Under CHF 50": "Geschenke unter CHF 50",
  "Gifts Under €50": "Geschenke unter € 50",
  "Gifts Under $100": "Geschenke unter $ 100",
  "Gifts Under £100": "Geschenke unter £ 100",
  "Gifts Under KR 1000": "Geschenke unter KR 1000",
  "Under NOK 1000": "Geschenke unter NOK 1000",
  "Under PLN 500": "Geschenke unter PLN 500",
  "Under CHF 100": "Geschenke unter CHF 100",
  "Under €100": "Geschenke unter € 100",
  "Under $200": "Geschenke unter $ 200",
  "Under £200": "Geschenke unter £ 200",
  "Under KR 2000": "Geschenke unter KR 2000",
  "Under NOK 2000": "Geschenke unter NOK 2000",
  "Under PLN 1000": "Geschenke unter PLN 1000",
  "Under CHF 200": "Geschenke unter CHF 200",
  "Under €200": "Geschenke unter € 200",
  "Run Gifts for Him": "Geschenke für Läufer:innen",
  "Climb Gifts for Him": "Geschenke für alle, die klettern",
  "Ski + Snow for Him": "Geschenke für Ski & Snowboard",
  "Hike Gifts for Him": "Geschenke für alle, die gern wandern",
  "Veilance Gifts for Him": "Geschenke von Veilance",
  "For Her": "Für sie",
  "Gifts for Her": "Geschenke für sie",
  "Run Gifts for Her": "Geschenke für Läufer:innen",
  "Climb Gifts for Her": "Geschenke für alle, die klettern",
  "Ski + Snow for Her": "Geschenke für Ski & Snowboard",
  "Hike Gifts for Her": "Geschenke für alle, die gern wandern",
  "Veilance Gifts for Her": "Geschenke von Veilance",
  "Year of the Dragon Capsule": "Capsule-Kollektion zum Jahr des Drachen",
  "ePE & Fabric Sustainability": "ePE & Nachhaltigkeit der Materialien",
  "Men's Footwear": "Herrenschuhe",
  "Men's Hike": "Wandern Herren",
  "Men's Climb": "Klettern Herren",
  "Men's Run": "Laufen Herren",
  "Women's Footwear": "Damenschuhe",
  "Women's Hike": "Wandern Damen",
  "Women's Climb": "Klettern Damen",
  "Women's Run": "Laufen Damen",
  "Footwear Innovation": "Footwear Innovation",
  "Ski-and-Snowboard": "Ski & Snowboard",
  Touring: "Tourengehen",
  Freeride: "Freeride",
  Resort: "Resort",
  Trail: "Trail",
  "Mountain Run": "Trail Run",
  Hike: "Wandern",
  Alpine: "Alpin",
  Rock: "Am Fels",
  Boulder: "Bouldern",
  Climbing: "Klettern",
  EverydayEvolution: "Everyday",
  "Micon LiTRIC Avalanche Airbag": "MiCON LiTRIC Lawinenrucksack",
  "Stocking Stuffers": "Accessoires",
  "Small Gifts": "Kleine Geschenke",
  "Warm Layers": "Warme Lagen",
  "US-BlackFriday": "Black Friday Bestseller",
};
