export const data = {
  "$^AboutUs^$": "关于我们",
  "$^CATEGORY^$": "分类",
  "$^ContactUs^$": "联系我们",
  "$^Country^$": "国家",
  "$^FindAStoreBuyNow^$": "查找店铺",
  "$^Menu^$": "菜单",
  "$^Newsroom^$": "Newsroom",
  "$^Product-Pro^$": "Pro人员专属",
  "$^ProductCare^$": "产品保养",
  "$^ProductService^$": "产品服务",
  "$^ProfessionalUse^$": "Arc'teryx PRO",
  "$^Professional^$": "$^Professional^$",
  "$^PromoHolidayShippingAlert^$": "免费快递服务截止日期12月17日",
  "$^SignUpBodyCopy2^$": "订阅接收新产品发布、独家折扣代码、活动邀请和奖品赢取信息。",
  "$^SignUpCta2^$": "订阅电子邮件",
  "$^SignUpTitle^$": "了解最新消息",
  "$^System-A^$": "$^System-A^$",
  "$^Veilance^$": "$^Veilance^$",
  "$^arc-naming-scheme^$": "Arc'teryx命名方案",
  "$^climbing-lookbook^$": "女性攀岩造型录",
  "$^community-events^$": "社区活动",
  "$^explore^$": "查看",
  "$^gift-guide^$": "节日礼物指南",
  "$^filterBar-collection^$": "系列",
  "$^footer-beFirstToKnow^$": "了解最新消息",
  "$^footer-findArcStore^$": "查找Arc'teryx店铺",
  "$^footer-followUs-header^$": "关注我们",
  "$^footer-locateBrandStoreOrPartner^$": "查找您所在地区的品牌店铺、合作店铺或零售合作伙伴。",
  "$^footer-nav-campaign-and-events^$": "活动",
  "$^footer-nav-careers^$": "招聘",
  "$^footer-nav-toTop^$": "返回顶部",
  "$^footer-newMoreInfoHelp^$": "了解更多信息？ 维修问题？ 没有问题。我们随时为您提供帮助。",
  "$^footer-pro-program^$": "Pro计划",
  "$^footer-recall-info^$": "召回信息",
  "$^footer-sitemap^$": "Sitemap",
  "$^freeShippingAndReturnsMsgJP^$": "所有订单均可享受免费邮寄服务--30天内免费退货",
  "$^nav-CommuterBags^$": "通勤包",
  "$^nav-IntendedUse^$": "用途",
  "$^nav-ShopMore^$": "更多商品",
  "$^nav-arcAcademy^$": "Arc'teryx学院",
  "$^nav-arcFavourites^$": "Arc'teryx 畅销产品",
  "$^nav-avalancheAirbags^$": "雪崩安全气囊",
  "$^nav-birdBlog^$": "BIRD博客",
  "$^nav-boots^$": "靴子",
  "$^nav-chalkBags^$": "粉袋",
  "$^nav-daypacks^$": "日用背包",
  "$^nav-duffleBagsAndTotes^$": "行李袋和手提包",
  "$^nav-fallLookbook^$": "秋季造型录",
  "$^nav-find-answers^$": "查找答案",
  "$^nav-giftCards^$": "礼品卡",
  "$^nav-gifts-for-him^$": "给他的礼物",
  "$^nav-gloves^$": "手套",
  "$^nav-guidePicks^$": "向导之选",
  "$^nav-harnesses^$": "安全带",
  "$^nav-hatsAndCaps^$": "帽子",
  "$^nav-hikingAndTrekking^$": "徒步&穿越",
  "$^nav-hydration-vests^$": "水袋背心",
  "$^nav-layering-guide^$": "叠穿指南",
  "$^nav-midLayerAndFleece^$": "中间层&抓绒衣",
  "$^nav-midLayer^$": "中间层",
  "$^nav-more^$": "更多...",
  "$^nav-multiDay^$": "多日",
  "$^nav-ourStory^$": "我们的故事",
  "$^nav-problemSolvers^$": "解决问题的人",
  "$^nav-ropeBags^$": "绳袋",
  "$^nav-shellFinder^$": "夹克查找器",
  "$^nav-gift-guide^$": "礼物指南",
  "$^nav-footwearFinder^$": "鞋履查找器",
  "$^nav-shoes^$": "鞋履",
  "$^nav-skiAndSnowboard^$": "单双板滑雪",
  "$^nav-springLookbook^$": "春季型录",
  "$^nav-sustainability^$": "可持续发展",
  "$^nav-toquesAndBeanies^$": "无边帽",
  "$^nav-trail-running^$": "越野跑",
  "$^nav-travelAndCommute^$": "旅行和通勤",
  "$^nav-travelBags^$": "旅行袋",
  "$^nav-travelCollection^$": "旅行",
  "$^nav-veilance^$": "Veilance",
  "$^nav-whoweare^$": "品牌故事",
  "$^nav-winterLookbook^$": "冬季造型录",
  "$^telephoneAssistanceES^$": "$^telephoneAssistanceES^$",
  "$^telephoneAssistanceIT^$": "$^telephoneAssistanceIT^$",
  "$^whatsNew^$": "查看新品",
  "10 Years": "10年",
  "About Us": "关于我们",
  Accessibility: "Accessibility",
  Accessories: "配件",
  "Alpine & Rock Climbing": "登山&攀岩",
  "Android App": "安卓App",
  "Arc'teryx Limited Warranty": "Arcteryx有限保修",
  "Arc'teryx Name": "Arc'teryx",
  "Arc'teryx Outlet FAQ": "Arc'teryx折扣店常见问题",
  "Arc'type": "Arc'type",
  "Athletes & Ambassadors": "运动员和形象大使",
  "Base Layer": "内层衣服",
  Bottoms: "下装",
  Brand: "品牌",
  "CA Supply Chains Act": "CA Supply Chains Act",
  Checkout: "结帐",
  Climate: "气候",
  "Climbing Gear": "攀爬装备",
  Clothing: "服装",
  Collection: "系列",
  "Cookie Preference Centre": "缓存偏好设置中心",
  "Cookie Policy": "Cookies政策",
  "Customer Support Centre": "客户支持中心",
  "Do Not Sell My Personal Information": "不要出售我的个人信息",
  Editorial: "评论",
  "Email List": "电子邮件列表",
  Everyday: "城市休闲",
  Exclusives: "经典回归",
  "FREE RETURNS": "免费退货",
  "FREE SHIPPING | FREE RETURNS": "包邮/免费退货",
  "Fall/Winter 2020": "Fall/Winter 2020",
  "Fall/Winter 2021": "Fall/Winter 2021",
  "Fast and Light": "快速轻薄",
  Favourites: "畅销产品",
  Fleece: "抓绒",
  "Climbing-Gear": "攀爬装备",
  Footwear: "鞋履",
  "Free 2 Day Shipping": "免费快递服务",
  "Free Returns": "免费退货",
  "Freedom of Movement": "Freedom of Movement",
  "Hot/Humid": "湿热",
  "Insulated Jackets": "保暖夹克",
  Jackets: "夹克",
  "Join Our Mailing List": "请加入我们的邮寄列表",
  "Just Added": "新品",
  "Learn More": "了解更多",
  "Leather Casing Goods": "皮套产品",
  Mens: "男装",
  "My Account": "我的帐户",
  "My Profile": "我的个人资料",
  "New Arrivals": "新品",
  "Beta Family": "Beta家族",
  "Gamma Collection": "Gamma系列",
  "Sun-Protection Collection": "防晒系列",
  "Athlete Picks": "运动员之选",
  "Online Exclusives": "Online Exclusives",
  "Order Tracking": "订单追踪",
  "Outlet Name": "Outlet",
  Packs: "背包",
  Pants: "裤装",
  "Past, Present, and Future": "过去、现在和未来",
  "Performance Denim": "功能性牛仔布",
  "Privacy Policy": "隐私政策",
  "Problem Solvers": "解决问题的人",
  "Procline Boots Safety Recall": "PROCLINE靴主动召回",
  "Procline Voluntary Recall": "Arc'teryx 正在主动召回所有型号PROCLINE靴",
  "Product Care": "产品保养",
  "Product Service": "产品服务",
  "Professional Use": "Arc'teryx PRO",
  Rain: "雨天",
  "Re-System": "Re-System",
  "Re-System Name": "Re-System",
  "Returns & Refunds": "退货&退款",
  Search: "搜索",
  "Severe Cold": "严寒",
  "Shell Jackets": "软壳夹克",
  "Just Landed": "新品上市",
  "Shipping & Delivery": "货运&投递",
  "Shirts & Tops": "衬衫&上衣",
  "Shirts & Tanks": "衬衫和背心",
  Shop: "购买",
  "Shop Arc'teryx": "选购Arc'teryx",
  "Shop Outlet": "选购折扣店",
  "Shop Used Gear": "选购二手装备",
  Shorts: "短裤",
  "Sign Out": "退出",
  "Sign Up for Emails": "订阅电子邮件",
  "Sign-In": "登录",
  "Sign-Out": "Sign-Out",
  "Sizing & Fit Guide": "版型尺寸表",
  "Ski & Snowboard Collection": "畅销外套",
  "Dresses & Skirts": "裙装",
  Socks: "袜子",
  "Specified Commercial Transaction Act": "Specified Commercial Transaction Act",
  "Spring/Summer 2020": "Spring/Summer 2020",
  "Spring/Summer 2021": "2021年春夏",
  "Spring/Summer 2022": "2022年春夏",
  "Stay updated with brand and product news.": "随时了解品牌和产品新闻。",
  "Student Discount": "学生折扣",
  "System_A Name": "System_A",
  "Terms & Conditions": "条款与条件",
  "Terms of Use": "使用条款",
  Tops: "上装",
  "Used Gear Name": "Used Gear",
  REGEAR: "REGEAR",
  "Veilance Name": "Veilance",
  "Veilance Womens": "Veilance Womens",
  "Veilance X Chito": "Veilance X Chito",
  "Veilance is a brand of Arc'teryx Equipment arcteryx.com":
    'Veilance是Arc\'teryx Equipment <a href="https://arcteryx.com">arcteryx.com</a>旗下的一个品牌。',
  "General FAQ": "常见问题",
  Wind: "多风",
  Womens: "女装",
  "free Shipping And Returns Msg JP": "所有订单均可享受免费邮寄服务--30天内免费退货",
  "iOS App": "iOS App",
  "WCAG Statement":
    "Arc’teryx is committed to achieving Level AA conformance for this website in conformance with the Web Content Accessibility Guidelines (WCAG) 2.0 and achieving compliance with other accessibility standards. Please contact Customer Service at +1 866 458 2473 (toll free), if you have any issues accessing information on this website.",
  "Obsessive Design": "Obsessive Design",
  "Product Advice": "Arc'teryx Advice",
  "Black Friday Sale": "黑色星期五特卖",
  "Sale": "特卖",
  "Women's Black Friday Sale": "女士Black Friday特卖",
  "Men's Black Friday Sale": "男士Black Friday特卖",
  "Colour Collection": "色彩系列",
  "What's Your Warmest": "你最保暖的是什么",
  "For Him": "为他选购",
  "Gifts for Him": "送给他的礼物",
  "Best Sellers": "畅销礼物",
  "Gifts Under $50": "50美元以下的礼物",
  "Gifts Under £50": "50美元以下的礼物",
  "Gifts Under KR 500": "500美元以下的礼物",
  "Gifts Under NOK 500": "500美元以下的礼物",
  "Gifts Under PLN 200": "200美元以下的礼物",
  "Gifts Under CHF 50": "50美元以下的礼物",
  "Gifts Under €50": "50美元以下的礼物",
  "Gifts Under $100": "100美元以下的礼物",
  "Gifts Under £100": "100美元以下的礼物",
  "Gifts Under KR 1000": "1000美元以下的礼物",
  "Under NOK 1000": "1000美元以下的礼物",
  "Under PLN 500": "500美元以下的礼物",
  "Under CHF 100": "100美元以下的礼物",
  "Under €100": "100美元以下的礼物",
  "Under $200": "200美元以下的礼物",
  "Under £200": "200美元以下的礼物",
  "Under KR 2000": "2000美元以下的礼物",
  "Under NOK 2000": "2000美元以下的礼物",
  "Under PLN 1000": "1000美元以下的礼物",
  "Under CHF 200": "200美元以下的礼物",
  "Under €200": "200美元以下的礼物",
  "Run Gifts for Him": "送给跑者的礼物",
  "Climb Gifts for Him": "送给攀岩者的礼物",
  "Ski + Snow for Him": "送给单双板滑雪者的礼物",
  "Hike Gifts for Him": "送给徒步者的礼物",
  "Veilance Gifts for Him": "Veilance礼物",
  "For Her": "为她选购",
  "Gifts for Her": "送给她的礼物",
  "Run Gifts for Her": "送给跑者的礼物",
  "Climb Gifts for Her": "送给攀岩者的礼物",
  "Ski + Snow for Her": "送给单双板滑雪者的礼物",
  "Hike Gifts for Her": "送给徒步者的礼物",
  "Veilance Gifts for Her": "Veilance礼物",
  "Year of the Dragon Capsule": "龙年胶囊系列",
  "ePE & Fabric Sustainability": "ePE和面料的可持续性",
  "Men's Footwear": "男装鞋品",
  "Men's Hike": "男士徒步系列",
  "Men's Climb": "男士攀岩系列",
  "Men's Run": "男士跑步系列",
  "Women's Footwear": "女装鞋品",
  "Women's Hike": "女士徒步系列",
  "Women's Climb": "女士攀岩系列",
  "Women's Run": "女士跑步系列",
  "Footwear Innovation": "鞋履创新",
  "Ski-and-Snowboard": "单双板滑雪",
  "Touring": "越野滑雪",
  "Freeride": "自由式滑雪",
  "Resort": "雪场滑雪",
  "Trail": "越野",
  "Mountain Run": "山地跑",
  "Hike": "徒步",
  "Alpine": "登山",
  "Rock": "攀岩",
  "Boulder": "抱石",
  "Climbing": "攀岩",
  "EverydayEvolution": "日常穿着",
  "Micon LiTRIC Avalanche Airbag": "MiCON LiTRIC 雪崩背包",
  "Stocking Stuffers": "圣诞袜小礼物",
  "Small Gifts": "小礼物",
  "Warm Layers": "保暖叠穿层",
  "US-BlackFriday": "黑色星期五畅销装备",
};
