export const data = {
  "$^AboutUs^$": "À Propos",
  "$^CATEGORY^$": "Catégorie",
  "$^ContactUs^$": "Contactez-nous",
  "$^Country^$": "Pays",
  "$^FindAStoreBuyNow^$": "Magasins",
  "$^Menu^$": "Menu",
  "$^Newsroom^$": "Newsroom",
  "$^Product-Pro^$": "Exclusif Programme Pro",
  "$^ProductCare^$": "Entretien produits",
  "$^ProductService^$": "Service produit",
  "$^ProfessionalUse^$": "Arc'teryx PRO",
  "$^Professional^$": "$^Professional^$",
  "$^PromoHolidayShippingAlert^$": "LIVRAISON AVANT LES VACANCES : LE 17 DÉCEMBRE",
  "$^SignUpBodyCopy2^$":
    "Inscrivez-vous pour recevoir des infos sur les nouveautés, des codes de réduction exclusifs, des invitations à des événements et une chance de gagner.",
  "$^SignUpCta2^$": "RECEVOIR LES E-MAILS",
  "$^SignUpTitle^$": "SOYEZ LES PREMIERS INFORMÉS",
  "$^System-A^$": "$^System-A^$",
  "$^Veilance^$": "$^Veilance^$",
  "$^arc-naming-scheme^$": "Système d’appellation des produits Arc'teryx",
  "$^climbing-lookbook^$": "Lookbook Escalade",
  "$^community-events^$": "Événements Arc’teryx",
  "$^explore^$": "Découvrir",
  "$^gift-guide^$": "Guide Cadeau",
  "$^filterBar-collection^$": "Collection",
  "$^footer-beFirstToKnow^$": "Soyez parmi les premiers informés",
  "$^footer-findArcStore^$": "Localiser un magasin Arc'teryx",
  "$^footer-followUs-header^$": "Suivez-nous",
  "$^footer-locateBrandStoreOrPartner^$":
    "Trouver un magasin de la marque, un magasin partenaire ou un partenaire détaillant près de chez vous.",
  "$^footer-nav-campaign-and-events^$": "Campagne & événement",
  "$^footer-nav-careers^$": "Emploi",
  "$^footer-nav-toTop^$": "Haut de page",
  "$^footer-newMoreInfoHelp^$":
    "Besoin de plus d’informations ? Une question concernant une réparation ? Pas de problème. Nous sommes là pour vous aider.",
  "$^footer-pro-program^$": "Programme PRO",
  "$^footer-recall-info^$": "Informations sur le rappel",
  "$^footer-sitemap^$": "Sitemap",
  "$^freeShippingAndReturnsMsgJP^$": "Livraison gratuite sur toutes les commandes - retours gratuits dans les 30 jours",
  "$^nav-CommuterBags^$": "Sacs pour navetteurs",
  "$^nav-IntendedUse^$": "Utilisation voulue",
  "$^nav-ShopMore^$": "Acheter plus",
  "$^nav-arcAcademy^$": "Arc'teryx Academy",
  "$^nav-arcFavourites^$": "Nos coups de cœur",
  "$^nav-avalancheAirbags^$": "Sacs airbag",
  "$^nav-birdBlog^$": "Bird Blog",
  "$^nav-boots^$": "Chaussures montantes",
  "$^nav-chalkBags^$": "Sacs à magnésie",
  "$^nav-daypacks^$": "Sac à dos pour la journée",
  "$^nav-duffleBagsAndTotes^$": "Sacs Duffles & Cabas",
  "$^nav-fallLookbook^$": "Lookbook Automne",
  "$^nav-find-answers^$": "Trouver des réponses",
  "$^nav-giftCards^$": "Cartes cadeaux",
  "$^nav-gifts-for-him^$": "Cadeaux Pour Lui",
  "$^nav-gloves^$": "Gants",
  "$^nav-guidePicks^$": "Choix des guides",
  "$^nav-harnesses^$": "Baudrier",
  "$^nav-hatsAndCaps^$": "Chapeaux & Casquettes",
  "$^nav-hikingAndTrekking^$": "Randonnée & Trekking",
  "$^nav-hydration-vests^$": "Sacs d'hydratation",
  "$^nav-layering-guide^$": "L’art du layering",
  "$^nav-midLayerAndFleece^$": "Couches intermédiaires & Polaires",
  "$^nav-midLayer^$": "Couches intermédiaires",
  "$^nav-more^$": "Plus...",
  "$^nav-multiDay^$": "Pour plusieurs jours",
  "$^nav-ourStory^$": "Notre histoire",
  "$^nav-problemSolvers^$": "Agents du changement",
  "$^nav-ropeBags^$": "Sacs à cordes",
  "$^nav-shellFinder^$": "Trouver la veste idéale",
  "$^nav-gift-guide^$": "Guide Cadeau",
  "$^nav-footwearFinder^$": "Trouver les chaussures idéales",
  "$^nav-shoes^$": "Chaussures",
  "$^nav-skiAndSnowboard^$": "Ski & Snowboard",
  "$^nav-springLookbook^$": "Lookbook de printemps",
  "$^nav-sustainability^$": "Développement durable",
  "$^nav-toquesAndBeanies^$": "Toques & Bonnets",
  "$^nav-trail-running^$": "Trail Running",
  "$^nav-travelAndCommute^$": "Pour les trajets, courts ou longs",
  "$^nav-travelBags^$": "Sacs de voyage",
  "$^nav-travelCollection^$": "Voyage",
  "$^nav-veilance^$": "Veilance",
  "$^nav-whoweare^$": "Notre ADN",
  "$^nav-winterLookbook^$": "Lookbook Hiver",
  "$^telephoneAssistanceES^$": "$^telephoneAssistanceES^$",
  "$^telephoneAssistanceIT^$": "$^telephoneAssistanceIT^$",
  "$^whatsNew^$": "Nouveautés",
  "10 Years": "10 ans",
  "About Us": "À propos de nous",
  Accessibility: "Accessibilité",
  Accessories: "Accessoires",
  "Alpine & Rock Climbing": "Alpinisme & Escalade",
  "Android App": "Appli Android",
  "Arc'teryx Limited Warranty": "Garantie limitée Arc’teryx",
  "Arc'teryx Name": "Arc'teryx",
  "Arc'teryx Outlet FAQ": "FAQ Arc’teryx Outlet",
  "Arc'type": "Arc'type",
  "Athletes & Ambassadors": "Athlètes et Ambassadeurs",
  "Base Layer": "Premières couches",
  Bottoms: "Pantalons",
  Brand: "Marque",
  "CA Supply Chains Act": "CA Supply Chains Act",
  Checkout: "Commander",
  Climate: "Climat",
  "Climbing Gear": "Équipement d'escalade",
  Clothing: "Vêtements",
  Collection: "COLLECTION",
  "Cookie Preference Centre": "Centre de préférences relatif aux cookies",
  "Cookie Policy": "Politique en matière de cookies",
  "Customer Support Centre": "Service Client",
  "Do Not Sell My Personal Information": "Ne pas vendre mes données personnelles",
  Editorial: "Éditorial",
  "Email List": "Mailing list",
  Everyday: "Everyday",
  Exclusives: "À nouveau en stock",
  "FREE RETURNS": "RETOURS GRATUITS",
  "FREE SHIPPING | FREE RETURNS": "LIVRAISON GRATUITE  /  RETOURS GRATUITS",
  "Fall/Winter 2020": "Automne/Hiver 2020",
  "Fall/Winter 2021": "Automne/Hiver 2021",
  "Fast and Light": "Fast and Light",
  Favourites: "Favoris",
  Fleece: "Polaire",
 "Climbing-Gear": "Équipement d'escalade",
  Footwear: "Chaussures",
  "Free 2 Day Shipping": "Livraison Express Gratuite",
  "Free Returns": "RETOURS GRATUITS",
  "Freedom of Movement": "Liberté de Mouvement",
  "Hot/Humid": "Chaud/Humide",
  "Insulated Jackets": "Vestes isolantes",
  Jackets: "Vestes",
  "Join Our Mailing List": "Rejoignez notre mailing-list",
  "Just Added": "Nouveautés",
  "Learn More": "Plus d’information",
  "Leather Casing Goods": "Portefeuilles en cuir",
  Mens: "Homme",
  "My Account": "Mon Compte",
  "My Profile": "Mon profil",
  "New Arrivals": "Nouveautés",
  "Beta Family": "Gamme Beta",
  "Gamma Collection": "Collection Gamma",
  "Sun-Protection Collection": "Collection Anti-UV",
  "Athlete Picks": "L’avis pros",
  "Online Exclusives": "Online Exclusives",
  "Order Tracking": "Suivi de commande",
  "Outlet Name": "Outlet",
  Packs: "Sacs",
  Pants: "Pantalons",
  "Past, Present, and Future": "Passé, présent et futur",
  "Performance Denim": "Performance Denim",
  "Privacy Policy": "Politique de Confidentialité",
  "Problem Solvers": "Agents du changement",
  "Procline Boots Safety Recall": "Procline Rappel Volontaire",
  "Procline Voluntary Recall": "Arc'teryx effectue un rappel volontaire des modèles de chaussures Procline suivants",
  "Product Care": "Entretien produits",
  "Product Service": "Service produit",
  "Professional Use": "Arc'teryx PRO",
  Rain: "Pluie",
  "Re-System": "Re-System",
  "Re-System Name": "Re-System",
  "Returns & Refunds": "Retours et Remboursements",
  Search: "recherche",
  "Severe Cold": "Grand froid",
  "Shell Jackets": "Vestes shell",
  "Just Landed": "Derniers ajouts",
  "Shipping & Delivery": "Expédition Et Livraison",
  "Shirts & Tops": "Chemises & Tops",
  "Shirts & Tanks": "T-shirts & Débardeurs",
  Shop: "Boutique",
  "Shop Arc'teryx": "Acheter des produits Arc’teryx",
  "Shop Outlet": "Acheter via Arc’teryx Outlet",
  "Shop Used Gear": "Acheter via notre programme Used Gear",
  Shorts: "Shorts",
  "Sign Out": "Déconnexion",
  "Sign Up for Emails": "S’abonner à nos e-mails",
  "Sign-In": "Identifiez-vous",
  "Sign-Out": "Sign-Out",
  "Sizing & Fit Guide": "Grilles de tailles",
  "Ski & Snowboard Collection": "Nos Vestes Stars",
  "Dresses & Skirts": "Robes & Jupes",
  Socks: "Chaussettes",
  "Specified Commercial Transaction Act": "Specified Commercial Transaction Act",
  "Spring/Summer 2020": "Spring/Summer 2020",
  "Spring/Summer 2021": "Printemps/Été 2021",
  "Spring/Summer 2022": "Printemps/Été 2022",
  "Stay updated with brand and product news.":
    "Suivez notre actualité avec des informations sur la marque et les produits.",
  "Student Discount": "Réduction étudiants",
  "System_A Name": "System_A",
  "Terms & Conditions": "Conditions générales",
  "Terms of Use": "Conditions d’utilisation",
  Tops: "Hauts",
  "Used Gear Name": "Used Gear",
  REGEAR: "REGEAR",
  "Veilance Name": "Veilance",
  "Veilance Womens": "Veilance Womens",
  "Veilance X Chito": "Veilance X Chito",
  "Veilance is a brand of Arc'teryx Equipment arcteryx.com":
    'Veilance est une marque d’Arc\'teryx Equipment <a href="https://arcteryx.com">arcteryx.com</a>',
  "General FAQ": "Foire aux questions",
  Wind: "Vent",
  Womens: "Femme",
  "free Shipping And Returns Msg JP":
    "Livraison gratuite sur toutes les commandes - retours gratuits dans les 30 jours",
  "iOS App": "Appli iOS",
  "WCAG Statement":
    "Arc’teryx s’engage à ce que ce site internet atteigne le niveau AA de conformité avec les Directives d’accessibilité des contenus web (WCAG) 2.1 et qu’il réponde également à d’autres normes d’accessibilité. Si vous rencontrez des problèmes pour accéder aux informations que vous recherchez sur ce site, veuillez contacter notre Service client au +1 866 458 2473 (numéro gratuit).",
  "Obsessive Design": "Obsessive Design",
  "Product Advice": "Arc'teryx Advice",
  "Black Friday Sale": "Promotion Black Friday",
  "Sale": "Promotion",
  "Women's Black Friday Sale": "Promo Black Friday pour femme",
  "Men's Black Friday Sale": "Promo Black Friday pour homme",
  "Colour Collection": "Gamme de couleurs",
  "What's Your Warmest": "Modèles les plus chauds",
  "For Him": "Pour lui",
  "Gifts for Him": "Cadeaux pour lui",
  "Best Sellers": "Meilleures ventes",
  "Gifts Under $50": "Cadeaux à moins de 50 $",
  "Gifts Under £50": "Cadeaux à moins de 50 £",
  "Gifts Under KR 500": "Cadeaux à moins de 500 KR",
  "Gifts Under NOK 500": "Cadeaux à moins de 500 NOK",
  "Gifts Under PLN 200": "Cadeaux à moins de 200 PLN",
  "Gifts Under CHF 50": "Cadeaux à moins de 50 CHF",
  "Gifts Under €50": "Cadeaux à moins de 50 €",
  "Gifts Under $100": "Cadeaux à moins de 100 $",
  "Gifts Under £100": "Cadeaux à moins de 100 £",
  "Gifts Under KR 1000": "Cadeaux à moins de 1000 KR",
  "Under NOK 1000": "Cadeaux à moins de 1000 NOK",
  "Under PLN 500": "Cadeaux à moins de 500 PLN",
  "Under CHF 100": "Cadeaux à moins de 100 CHF",
  "Under €100": "Cadeaux à moins de 100 €",
  "Under $200": "Cadeaux à moins de 200 $",
  "Under £200": "Cadeaux à moins de 200 £",
  "Under KR 2000": "Cadeaux à moins de 2000 KR",
  "Under NOK 2000": "Cadeaux à moins de 2000 NOK",
  "Under PLN 1000": "Cadeaux à moins de 1000 PLN",
  "Under CHF 200": "Cadeaux à moins de 200 CHF",
  "Under €200": "Cadeaux à moins de 200 €",
  "Run Gifts for Him": "Cadeaux pour les coureurs",
  "Climb Gifts for Him": "Cadeaux pour les grimpeurs",
  "Ski + Snow for Him": "Cadeaux pour les skieurs et snowboardeurs",
  "Hike Gifts for Him": "Cadeaux pour les randonneurs",
  "Veilance Gifts for Him": "Cadeaux Veilance",
  "For Her": "Pour elle",
  "Gifts for Her": "Cadeaux pour elle",
  "Run Gifts for Her": "Cadeaux pour les coureurs",
  "Climb Gifts for Her": "Cadeaux pour les grimpeurs",
  "Ski + Snow for Her": "Cadeaux pour les skieurs et snowboardeurs",
  "Hike Gifts for Her": "Cadeaux pour les randonneurs",
  "Veilance Gifts for Her": "Cadeaux Veilance",
  "Year of the Dragon Capsule": "Capsule de l’année du dragon",
  "ePE & Fabric Sustainability": "ePE et matière responsable",
  "Men's Footwear": "Chaussures homme",
  "Men's Hike": "Randonnée homme",
  "Men's Climb": "Escalade homme",
  "Men's Run": "Running homme",
  "Women's Footwear": "Chaussures femme",
  "Women's Hike": "Randonnée femme",
  "Women's Climb": "Escalade femme",
  "Women's Run": "Running femme",
  "Footwear Innovation": "Innovations Chaussures",
  "Ski-and-Snowboard": "Ski & Snowboard",
  "Touring": "Ski de randonnée",
  "Freeride": "Freeride",
  "Resort": "Piste",
  "Trail": "Trail",
  "Mountain Run": "Trail en montagne",
  "Hike": "Randonnée",
  "Alpine": "Alpinisme",
  "Rock": "Rocher",
  "Boulder": "Bloc",
  "Climbing": "Escalade",
  "EverydayEvolution": "Pour tous les jours",
  "Micon LiTRIC Avalanche Airbag": "Sac airbag MiCON LiTRIC",
  "Stocking Stuffers": "Petits accessoires",
  "Small Gifts": "Petits cadeaux",
  "Warm Layers": "Habits chauds",
  "US-BlackFriday": "Black Friday : Produits Stars",
};
