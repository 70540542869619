export const data = {
  "$^AboutUs^$": "Om oss",
  "$^CATEGORY^$": "Kategori",
  "$^ContactUs^$": "Kontakt oss",
  "$^Country^$": "Land",
  "$^FindAStoreBuyNow^$": "Finn en butikk",
  "$^Menu^$": "Meny",
  "$^Newsroom^$": "Newsroom",
  "$^Product-Pro^$": "Eksklusivt for Pro-medlemmer",
  "$^ProductCare^$": "Vedlikehold av produktet",
  "$^ProductService^$": "Produktservice",
  "$^ProfessionalUse^$": "Arc'teryx PRO",
  "$^Professional^$": "$^Professional^$",
  "$^PromoHolidayShippingAlert^$": "FRIST FOR LEVERING FØR JUL 17. DES.",
  "$^SignUpBodyCopy2^$":
    "Bli abonnent og få meldinger om produktlanseringer, eksklusive rabattkoder, invitasjoner til arrangementer og muligheten til å vinne premier.",
  "$^SignUpCta2^$": "REGISTRER DEG FOR Å FÅ E-POST",
  "$^SignUpTitle^$": "FÅ NYHETENE FØRST",
  "$^System-A^$": "$^System-A^$",
  "$^Veilance^$": "$^Veilance^$",
  "$^arc-naming-scheme^$": "Navnesystemet til Arc'teryx",
  "$^climbing-lookbook^$": "Lookbook for klatring",
  "$^community-events^$": "Samfunnsrettede arrangementer",
  "$^explore^$": "Mer info",
  "$^gift-guide^$": "Gaveguide",
  "$^filterBar-collection^$": "Kolleksjon",
  "$^footer-beFirstToKnow^$": "Få nyhetene først",
  "$^footer-findArcStore^$": "Finn en Arc'teryx-butikk",
  "$^footer-followUs-header^$": "Følg oss",
  "$^footer-locateBrandStoreOrPartner^$": "Finn en merkebutikk, partnerbutikk eller forhandler i ditt område.",
  "$^footer-nav-campaign-and-events^$": "Kampanje og arrangement",
  "$^footer-nav-careers^$": "Jobb",
  "$^footer-nav-toTop^$": "Til toppen",
  "$^footer-newMoreInfoHelp^$":
    "Trenger du mer informasjon? Spørsmål om reparasjon? Ikke noe problem. Vi er her for å hjelpe.",
  "$^footer-pro-program^$": "Program for profesjonelle",
  "$^footer-recall-info^$": "Info om tilbakekalling",
  "$^footer-sitemap^$": "Sitemap",
  "$^freeShippingAndReturnsMsgJP^$": "Gratis frakt på alle bestillinger – gratis retur innen 30 dager",
  "$^nav-CommuterBags^$": "Bysekker",
  "$^nav-IntendedUse^$": "Tilsiktet bruk",
  "$^nav-ShopMore^$": "Shop mer",
  "$^nav-arcAcademy^$": "Arc'teryx Academy",
  "$^nav-arcFavourites^$": "Arc'-favoritter",
  "$^nav-avalancheAirbags^$": "Skredsekk med Airbag",
  "$^nav-birdBlog^$": "Bird Blog",
  "$^nav-boots^$": "Støvler",
  "$^nav-chalkBags^$": "Kalkposer",
  "$^nav-daypacks^$": "Dagstursekker",
  "$^nav-duffleBagsAndTotes^$": "Duffelbager & store håndvesker",
  "$^nav-fallLookbook^$": "Lookbook for høsten",
  "$^nav-find-answers^$": "Finn svar",
  "$^nav-giftCards^$": "Gavekort",
  "$^nav-gifts-for-him^$": "Gaver Til Ham",
  "$^nav-gloves^$": "Hansker",
  "$^nav-guidePicks^$": "Fjellførernes favoritter",
  "$^nav-harnesses^$": "Klatreseler",
  "$^nav-hatsAndCaps^$": "Hatter & caps",
  "$^nav-hikingAndTrekking^$": "Hiking & Trekking",
  "$^nav-hydration-vests^$": "Drikkevest",
  "$^nav-layering-guide^$": "Guide til lagvis påkledning",
  "$^nav-midLayerAndFleece^$": "Mellomlag og fleece",
  "$^nav-midLayer^$": "Mellomlag",
  "$^nav-more^$": "Mer ...",
  "$^nav-multiDay^$": "Flerdags",
  "$^nav-ourStory^$": "Vår historie",
  "$^nav-problemSolvers^$": "Problemløserne",
  "$^nav-ropeBags^$": "Tausekker",
  "$^nav-shellFinder^$": "Jakkefinner",
  "$^nav-gift-guide^$": "Gaveguide",
  "$^nav-footwearFinder^$": "Skofinner",
  "$^nav-shoes^$": "Sko",
  "$^nav-skiAndSnowboard^$": "Ski & Snowboard",
  "$^nav-springLookbook^$": "Lookbook for våren",
  "$^nav-sustainability^$": "Bærekraft",
  "$^nav-toquesAndBeanies^$": "Luer",
  "$^nav-trail-running^$": "Terrengløping",
  "$^nav-travelAndCommute^$": "Reise & jobb",
  "$^nav-travelBags^$": "Reisebager",
  "$^nav-travelCollection^$": "Reise",
  "$^nav-veilance^$": "Veilance",
  "$^nav-whoweare^$": "Hvem vi er",
  "$^nav-winterLookbook^$": "Vinterens Lookbook",
  "$^telephoneAssistanceES^$": "$^telephoneAssistanceES^$",
  "$^telephoneAssistanceIT^$": "$^telephoneAssistanceIT^$",
  "$^whatsNew^$": "Nyheter",
  "10 Years": "10 år",
  "About Us": "Om oss",
  Accessibility: "Accessibility",
  Accessories: "Tilbehør",
  "Alpine & Rock Climbing": "Topptur & klatring",
  "Android App": "Android App",
  "Arc'teryx Limited Warranty": "Arc'teryx begrensede garanti",
  "Arc'teryx Name": "Arc'teryx",
  "Arc'teryx Outlet FAQ": "Arc'teryx Outlet FAQ",
  "Arc'type": "Arc'type",
  "Athletes & Ambassadors": "Utøvere & Ambassadører",
  "Base Layer": "Undertøy",
  Bottoms: "Bukser",
  Brand: "Merke",
  "CA Supply Chains Act": "CA Supply Chains Act",
  Checkout: "Utsjekking",
  Climate: "Klima",
  "Climbing Gear": "Klatreutstyr",
  Clothing: "Klær",
  Collection: "KOLLEKSJON",
  "Cookie Preference Centre": "Preferansesenter for informasjonskapsler",
  "Cookie Policy": "Vilkår for bruk av informasjonskapsler",
  "Customer Support Centre": "Kundesenter",
  "Do Not Sell My Personal Information": "Ikke selg personopplysningene mine",
  Editorial: "Redaksjonelt",
  "Email List": "Epostliste",
  Everyday: "Everyday",
  Exclusives: "Tilbake på lager",
  "FREE RETURNS": "GRATIS RETUR",
  "FREE SHIPPING | FREE RETURNS": "GRATIS FRAKT  /  GRATIS RETUR",
  "Fall/Winter 2020": "Fall/Winter 2020",
  "Fall/Winter 2021": "Fall/Winter 2021",
  "Fast and Light": "Rask og lett",
  Favourites: "Favoritter",
  Fleece: "Fleece",
  "Climbing-Gear": "Klatreutstyr",
  Footwear: "Fottøy",
  "Free 2 Day Shipping": "Gratis Ekspressfrakt",
  "Free Returns": "GRATIS RETUR",
  "Freedom of Movement": "Freedom of Movement",
  "Hot/Humid": "Varmt/Fuktig",
  "Insulated Jackets": "Isolerte jakker",
  Jackets: "Jakker",
  "Join Our Mailing List": "Bli med på vår epostliste",
  "Just Added": "Nyheter",
  "Learn More": "Les Mer",
  "Leather Casing Goods": "Skinndeksler",
  Mens: "Herre",
  "My Account": "Min Konto",
  "My Profile": "Min profil",
  "New Arrivals": "Nyheter",
  "Beta Family": "Beta-familien",
  "Gamma Collection": "Gamma-kolleksjonen",
  "Sun-Protection Collection": "Solbeskyttelse-kolleksjon",
  "Athlete Picks": "Utøvernes favoritter",
  "Online Exclusives": "Online Exclusives",
  "Order Tracking": "Sporing av bestillinger",
  "Outlet Name": "Outlet",
  Packs: "Sekker",
  Pants: "Bukser",
  "Past, Present, and Future": "Fortid, nåtid og framtid",
  "Performance Denim": "Performance Denim",
  "Privacy Policy": "Personvern",
  "Problem Solvers": "Problemløserne",
  "Procline Boots Safety Recall": "Frivillig tilbakekalling av Procline",
  "Procline Voluntary Recall":
    "Arc'teryx Equipment gjennomfører en frivillig tilbakekalling av alle modeller av Procline Boot",
  "Product Care": "Vedlikehold av produktet",
  "Product Service": "Produktservice",
  "Professional Use": "Arc'teryx PRO",
  Rain: "Regn",
  "Re-System": "Re-System",
  "Re-System Name": "Re-System",
  "Returns & Refunds": "Retur og refusjon",
  Search: "søk",
  "Severe Cold": "Ekstrem kulde",
  "Shell Jackets": "Skalljakker",
  "Just Landed": "Nye produkter",
  "Shipping & Delivery": "Sending Og Levering",
  "Shirts & Tops": "Skjorter & overdeler",
  "Shirts & Tanks": "Skjorter & Singleter",
  Shop: "Gå Til Butikk",
  "Shop Arc'teryx": "Kjøp hos Arc'teryx",
  "Shop Outlet": "Kjøp i Arc'teryx Outlet",
  "Shop Used Gear": "Kjøp Arc'teryx Brukt utstyr",
  Shorts: "Shorts",
  "Sign Out": "Utlogging",
  "Sign Up for Emails": "Abonner på epost",
  "Sign-In": "Logg inn",
  "Sign-Out": "Sign-Out",
  "Sizing & Fit Guide": "Oversikter over størrelser",
  "Ski & Snowboard Collection": "Bestselgere Ytterklær",
  "Dresses & Skirts": "Kjoler & Skjørt",
  Socks: "Sokker",
  "Specified Commercial Transaction Act": "Specified Commercial Transaction Act",
  "Spring/Summer 2020": "Spring/Summer 2020",
  "Spring/Summer 2021": "Vår/Sommer 2021",
  "Spring/Summer 2022": "Vår/Sommer 2022",
  "Stay updated with brand and product news.": "Hold deg oppdatert på merket og våre produktnyheter.",
  "Student Discount": "Studentrabatt",
  "System_A Name": "System_A",
  "Terms & Conditions": "Vilkår",
  "Terms of Use": "Brukervilkår",
  Tops: "Overdeler",
  "Used Gear Name": "Used Gear",
  REGEAR: "REGEAR",
  "Veilance Name": "Veilance",
  "Veilance Womens": "Veilance Womens",
  "Veilance X Chito": "Veilance X Chito",
  "Veilance is a brand of Arc'teryx Equipment arcteryx.com":
    'Veilance er et merke fra Arc\'teryx Equipment - <a href="https://arcteryx.com">arcteryx.com</a>',
  "General FAQ": "Spørsmål",
  Wind: "Vind",
  Womens: "Dame",
  "free Shipping And Returns Msg JP": "Gratis frakt på alle bestillinger – gratis retur innen 30 dager",
  "iOS App": "iOS App",
  "WCAG Statement":
    "Arc’teryx is committed to achieving Level AA conformance for this website in conformance with the Web Content Accessibility Guidelines (WCAG) 2.0 and achieving compliance with other accessibility standards. Please contact Customer Service at +1 866 458 2473 (toll free), if you have any issues accessing information on this website.",
  "Obsessive Design": "Obsessive Design",
  "Product Advice": "Arc'teryx Advice",
  "Black Friday Sale": "Black Friday-Salg",
  "Sale": "Salg",
  "Women's Black Friday Sale": "Black Friday-salg Dame",
  "Men's Black Friday Sale": "Black Friday-salg Herre",
  "Colour Collection": "Colour Collection",
  "What's Your Warmest": "Hva er ditt/deres varmeste",
  "For Him": "Til han",
  "Gifts for Him": "Gaver til han",
  "Best Sellers": "Bestselgere",
  "Gifts Under $50": "Gaver under 50 $",
  "Gifts Under £50": "Gaver under 50 £",
  "Gifts Under KR 500": "Gaver under 500 KR",
  "Gifts Under NOK 500": "Gaver under 500 NOK",
  "Gifts Under PLN 200": "Gaver under 200 PLN",
  "Gifts Under CHF 50": "Gaver under 50 CHF",
  "Gifts Under €50": "Gaver under 50 €",
  "Gifts Under $100": "Gaver under 100 $",
  "Gifts Under £100": "Gaver under 100 £",
  "Gifts Under KR 1000": "Gaver under 1000 KR",
  "Under NOK 1000": "Gaver under 1000 NOK",
  "Under PLN 500": "Gaver under 500 PLN",
  "Under CHF 100": "Gaver under 100 CHF",
  "Under €100": "Gaver under 100 €",
  "Under $200": "Gaver under 200 $",
  "Under £200": "Gaver under 200 £",
  "Under KR 2000": "Gaver under 2000 KR",
  "Under NOK 2000": "Gaver under 2000 NOK",
  "Under PLN 1000": "Gaver under 1000 PLN",
  "Under CHF 200": "Gaver under 200 CHF",
  "Under €200": "Gaver under 200 €",
  "Run Gifts for Him": "Gaver til løpere",
  "Climb Gifts for Him": "Gaver til klatrere",
  "Ski + Snow for Him": "Gaver til ski- og snowboard-kjørere",
  "Hike Gifts for Him": "Gaver til turgåere",
  "Veilance Gifts for Him": "Veilance gaver",
  "For Her": "Til henne",
  "Gifts for Her": "Gaver til henne",
  "Run Gifts for Her": "Gaver til løpere",
  "Climb Gifts for Her": "Gaver til klatrere",
  "Ski + Snow for Her": "Gaver til ski- og snowboard-kjørere",
  "Hike Gifts for Her": "Gaver til turgåere",
  "Veilance Gifts for Her": "Veilance gaver",
  "Year of the Dragon Capsule": "Year of the Dragon Capsule",
  "ePE & Fabric Sustainability": "ePE og material-bærekraft",
  "Men's Footwear": "Fottøy til Herre",
  "Men's Hike": "Hiking Herre",
  "Men's Climb": "Klatring Herre",
  "Men's Run": "Løping Herre",
  "Women's Footwear": "Fottøy til Dame",
  "Women's Hike": "Hiking Dame",
  "Women's Climb": "Klatring Dame",
  "Women's Run": "Løping Dame",
  "Footwear Innovation": "Innovasjon innen fottøy",
  "Ski-and-Snowboard": "Ski & Snowboard",
  "Touring": "Skitouring",
  "Freeride": "Frikjøring",
  "Resort": "Alpinanlegg",
  "Trail": "Terrengløp",
  "Mountain Run": "Fjelløping",
  "Hike": "Fottur",
  "Alpine": "Fjellsport",
  "Rock": "Klippeklatring",
  "Boulder": "Buldring",
  "Climbing": "Klatring",
  "EverydayEvolution": "Everyday",
  "Micon LiTRIC Avalanche Airbag": "MiCON LiTRIC Avalanche Airbag",
  "Stocking Stuffers": "Perfekt til julestrømpen",
  "Small Gifts": "Små gaver",
  "Warm Layers": "Varme lag",
  "US-BlackFriday": "Black Friday Bestselgere",
};
