export const data = {
  "$^AboutUs^$": "会社情報",
  "$^CATEGORY^$": "カテゴリー",
  "$^ContactUs^$": "お問い合わせ",
  "$^Country^$": "国",
  "$^FindAStoreBuyNow^$": "取扱店を探す",
  "$^Menu^$": "メニュー",
  "$^Newsroom^$": "Newsroom",
  "$^Product-Pro^$": "Pro専用",
  "$^ProductCare^$": "製品のお手入れ",
  "$^ProductService^$": "製品サービス",
  "$^ProfessionalUse^$": "Arc'teryx PRO",
  "$^Professional^$": "$^Professional^$",
  "$^PromoHolidayShippingAlert^$": "ホリデー配送締切 12月17日",
  "$^SignUpBodyCopy2^$":
    "新製品リリース、特別割引コード、イベントへの招待、そして製品を無料で得られる特典を登録してゲットしよう。",
  "$^SignUpCta2^$": "メールマガジン登録",
  "$^SignUpTitle^$": "最新情報をいち早く",
  "$^System-A^$": "$^System-A^$",
  "$^Veilance^$": "$^Veilance^$",
  "$^arc-naming-scheme^$": "製品ネーミングについて",
  "$^climbing-lookbook^$": "ウィメンズのクライミング ルックブック",
  "$^community-events^$": "コミュニティイベント",
  "$^explore^$": "ストーリー",
  "$^gift-guide^$": "ホリデーギフトガイド",
  "$^filterBar-collection^$": "コレクション",
  "$^footer-beFirstToKnow^$": "最新の情報をいち早く",
  "$^footer-findArcStore^$": "アークテリクス ストアを探す",
  "$^footer-followUs-header^$": "アークテリクスをフォロー",
  "$^footer-locateBrandStoreOrPartner^$": "お住いのエリアのブランドストア、パートナーストアを探す。",
  "$^footer-nav-campaign-and-events^$": "キャンペーン・イベント情報",
  "$^footer-nav-careers^$": "採用情報",
  "$^footer-nav-toTop^$": "トップ",
  "$^footer-newMoreInfoHelp^$":
    "製品に関する情報、ご注文やリペアについてのご質問などがありましたらカスタマーサポートセンターへお問い合わせください。",
  "$^footer-pro-program^$": "Pro program",
  "$^footer-recall-info^$": "回収の情報",
  "$^footer-sitemap^$": "Sitemap",
  "$^freeShippingAndReturnsMsgJP^$":
    "休業期間：8/10(土)-8/15(木)｜8/9(金) AM10:00迄のご注文は即日発送｜休業中ご注文可能です。発送は16日より順次開始",
  "$^nav-CommuterBags^$": "コミューターバッグ",
  "$^nav-IntendedUse^$": "アクティビティ別",
  "$^nav-ShopMore^$": "特集別",
  "$^nav-arcAcademy^$": "アークテリクス アカデミー",
  "$^nav-arcFavourites^$": "アークのお気に入り",
  "$^nav-avalancheAirbags^$": "アバランチ エアバック",
  "$^nav-birdBlog^$": "Bird ブログ",
  "$^nav-boots^$": "ブーツ",
  "$^nav-chalkBags^$": "チョークバッグ",
  "$^nav-daypacks^$": "デイパック",
  "$^nav-duffleBagsAndTotes^$": "ダッフル&トート",
  "$^nav-fallLookbook^$": "秋のルックブック",
  "$^nav-find-answers^$": "答えを探す",
  "$^nav-giftCards^$": "ギフトカード",
  "$^nav-gifts-for-him^$": "男性用ギフト",
  "$^nav-gloves^$": "グローブ",
  "$^nav-guidePicks^$": "ガイドが選ぶ",
  "$^nav-harnesses^$": "ハーネス",
  "$^nav-hatsAndCaps^$": "ハット & キャップ",
  "$^nav-hikingAndTrekking^$": "ハイキング & トレッキング",
  "$^nav-hydration-vests^$": "ハイドレーション ベスト",
  "$^nav-layering-guide^$": "レイヤリングガイド",
  "$^nav-midLayerAndFleece^$": "ミッドレイヤー & フリース",
  "$^nav-midLayer^$": "ミッドレイヤー",
  "$^nav-more^$": "その他...",
  "$^nav-multiDay^$": "長期用パック",
  "$^nav-ourStory^$": "ストーリー",
  "$^nav-problemSolvers^$": "プロブレム・ソルバー（問題解決者）",
  "$^nav-ropeBags^$": "ロープバッグ",
  "$^nav-shellFinder^$": "ジャケットを見つける",
  "$^nav-gift-guide^$": "ギフトガイド",
  "$^nav-footwearFinder^$": "フットウェアファインダー",
  "$^nav-shoes^$": "シューズ",
  "$^nav-skiAndSnowboard^$": "スキー & スノーボード",
  "$^nav-springLookbook^$": "ルックブック 春",
  "$^nav-sustainability^$": "持続可能性",
  "$^nav-toquesAndBeanies^$": "トーク & ビーニー",
  "$^nav-trail-running^$": "トレイルランニング",
  "$^nav-travelAndCommute^$": "旅や通勤に",
  "$^nav-travelBags^$": "トラベル バッグ",
  "$^nav-travelCollection^$": "トラベル",
  "$^nav-veilance^$": "ヴェイランス",
  "$^nav-whoweare^$": "アークテリクスとは",
  "$^nav-winterLookbook^$": "冬のスタイルブック",
  "$^telephoneAssistanceES^$": "$^telephoneAssistanceES^$",
  "$^telephoneAssistanceIT^$": "$^telephoneAssistanceIT^$",
  "$^whatsNew^$": "新商品",
  "10 Years": "10年間",
  "About Us": "会社情報",
  Accessibility: "Accessibility",
  Accessories: "アクセサリ",
  "Alpine & Rock Climbing": "アルパイン & ロッククライミング",
  "Android App": "Androidアプリ",
  "Arc'teryx Limited Warranty": "アークテリクス限定保証",
  "Arc'teryx Name": "Arc'teryx",
  "Arc'teryx Outlet FAQ": "アークテリクス・アウトレット商品のよくある質問",
  "Arc'type": "Arc'type",
  "Athletes & Ambassadors": "アスリート＆アンバサダー",
  "Base Layer": "ベースレイヤー",
  Bottoms: "ボトムス",
  Brand: "ブランド",
  "CA Supply Chains Act": "CA Supply Chains Act",
  Checkout: "お支払い",
  Climate: "天候",
  "Climbing Gear": "クライミングギア",
  Clothing: "ウェア",
  Collection: "コレクション",
  "Cookie Preference Centre": "Cookie設定センター",
  "Cookie Policy": "クッキーポリシー",
  "Customer Support Centre": "カスタマー　サポート　センター",
  "Do Not Sell My Personal Information": "私の個人情報を販売しない",
  Editorial: "エディトリアル",
  "Email List": "メールリスト",
  Everyday: "エブリディ",
  Exclusives: "再入荷アイテム",
  "FREE RETURNS": "無料返品",
  "FREE SHIPPING | FREE RETURNS": "配送無料 / 返品送料無料",
  "Fall/Winter 2020": "Fall/Winter 2020",
  "Fall/Winter 2021": "Fall/Winter 2021",
  "Fast and Light": "ファスト＆ライト",
  Favourites: "お気に入り",
  Fleece: "フリース",
  "Climbing-Gear": "クライミングギア",
  Footwear: "フットウェア",
  "Free 2 Day Shipping": "無料速達配送",
  "Free Returns": "無料返品",
  "Freedom of Movement": "Freedom of Movement",
  "Hot/Humid": "暑さ/湿潤環境",
  "Insulated Jackets": "インサレーテッドジャケット",
  Jackets: "ジャケット",
  "Join Our Mailing List": "アークテリクスのメーリングリストに登録",
  "Just Added": "新商品",
  "Learn More": "詳細を見る",
  "Leather Casing Goods": "レザーケース",
  Mens: "メンズ",
  "My Account": "マイアカウント",
  "My Profile": "プロフィール",
  "New Arrivals": "新商品",
  "Beta Family": "ベータ ファミリー",
  "Gamma Collection": "ガンマ コレクション",
  "Sun-Protection Collection": "紫外線防止コレクション",
  "Athlete Picks": "アスリートのお勧め",
  "Online Exclusives": "Online Exclusives",
  "Order Tracking": "ご注文トラッキング",
  "Outlet Name": "Outlet",
  Packs: "パック",
  Pants: "パンツ",
  "Past, Present, and Future": "過去、現在、そして未来",
  "Performance Denim": "パフォーマンスデニム",
  "Privacy Policy": "プライバシーポリシー",
  "Problem Solvers": "プロブレムソルバー（問題解決に向う者）",
  "Procline Boots Safety Recall": "Procline Boot自主回収のお知らせ",
  "Procline Voluntary Recall": "本日Arc'teryx が販売する全てのProcline bootをリコールとして発表いたしました。",
  "Product Care": "製品のお手入れ",
  "Product Service": "製品サービス",
  "Professional Use": "Arc'teryx PRO",
  Rain: "雨",
  "Re-System": "Re-System",
  "Re-System Name": "Re-System",
  "Returns & Refunds": "返品と払戻し",
  Search: "検索",
  "Severe Cold": "極寒",
  "Shell Jackets": "シェルジャケット",
  "Just Landed": "新着",
  "Shipping & Delivery": "配送と配達",
  "Shirts & Tops": "シャツ & トップス",
  "Shirts & Tanks": "シャツ & タンク",
  Shop: "購入",
  "Shop Arc'teryx": "アークテリクスを購入",
  "Shop Outlet": "アウトレット商品を購入",
  "Shop Used Gear": "中古ギアを購入",
  Shorts: "ショートパンツ",
  "Sign Out": "サインアウト",
  "Sign Up for Emails": "メール購読に登録",
  "Sign-In": "サインイン",
  "Sign-Out": "Sign-Out",
  "Sizing & Fit Guide": "サイズ表",
  "Ski & Snowboard Collection": "アウターウエアの人気商品",
  "Dresses & Skirts": "ドレス & スカート",
  Socks: "ソックス",
  "Specified Commercial Transaction Act": "特定商取引法",
  "Spring/Summer 2020": "Spring/Summer 2020",
  "Spring/Summer 2021": "2021 春夏",
  "Spring/Summer 2022": "2022 春夏",
  "Stay updated with brand and product news.": "ブランドと製品の最新情報を入手。",
  "Student Discount": "学生割引はこちら",
  "System_A Name": "System_A",
  "Terms & Conditions": "ご利用規約",
  "Terms of Use": "利用規約",
  Tops: "トップス",
  "Used Gear Name": "Used Gear",
  REGEAR: "REGEAR",
  "Veilance Name": "Veilance",
  "Veilance Womens": "Veilance Womens",
  "Veilance X Chito": "Veilance X Chito",
  "Veilance is a brand of Arc'teryx Equipment arcteryx.com":
    'ヴェイランスはArc’teryx Equipment <a href="https://arcteryx.com">arcteryx.com</a>のブランドです',
  "General FAQ": "よくある質問",
  Wind: "風",
  Womens: "ウィメンズ",
  "free Shipping And Returns Msg JP": "送料無料",
  "iOS App": "iOSアプリ",
  "WCAG Statement":
    "Arc’teryx is committed to achieving Level AA conformance for this website in conformance with the Web Content Accessibility Guidelines (WCAG) 2.0 and achieving compliance with other accessibility standards. Please contact Customer Service at +1 866 458 2473 (toll free), if you have any issues accessing information on this website.",
  "Obsessive Design": "Obsessive Design",
  "Product Advice": "Arc'teryx Advice",
  "Black Friday Sale": "ブラックフライデーセール",
  Sale: "セール",
  "Women's Black Friday Sale": "ウィメンズ ブラックフライデーセール",
  "Men's Black Friday Sale": "メンズ ブラックフライデーセール",
  "Colour Collection": "カラーコレクション",
  "What's Your Warmest": "最も保温性が高い一着",
  "For Him": "男性向けギフト",
  "Gifts for Him": "男性向けギフト",
  "Best Sellers": "ベストセラー",
  "Gifts Under $50": "50ドル未満のギフト",
  "Gifts Under £50": "50ドル未満のギフト",
  "Gifts Under KR 500": "500ドル未満のギフト",
  "Gifts Under NOK 500": "500ドル未満のギフト",
  "Gifts Under PLN 200": "200ドル未満のギフト",
  "Gifts Under CHF 50": "50ドル未満のギフト",
  "Gifts Under €50": "50ドル未満のギフト",
  "Gifts Under $100": "100ドル未満のギフト",
  "Gifts Under £100": "100ドル未満のギフト",
  "Gifts Under KR 1000": "1000ドル未満のギフト",
  "Under NOK 1000": "1000ドル未満のギフト",
  "Under PLN 500": "500ドル未満のギフト",
  "Under CHF 100": "100ドル未満のギフト",
  "Under €100": "100ドル未満のギフト",
  "Under $200": "200ドル未満のギフト",
  "Under £200": "200ドル未満のギフト",
  "Under KR 2000": "2000ドル未満のギフト",
  "Under NOK 2000": "2000ドル未満のギフト",
  "Under PLN 1000": "1000ドル未満のギフト",
  "Under CHF 200": "200ドル未満のギフト",
  "Under €200": "200ドル未満のギフト",
  "Run Gifts for Him": "ランナー向けギフト",
  "Climb Gifts for Him": "クライマー向けギフト",
  "Ski + Snow for Him": "スキーヤー・スノーボーダー向けギフト",
  "Hike Gifts for Him": "ハイカー向けギフト",
  "Veilance Gifts for Him": "ヴェイランス ギフト",
  "For Her": "女性向けギフト",
  "Gifts for Her": "女性向けギフト",
  "Run Gifts for Her": "ランナー向けギフト",
  "Climb Gifts for Her": "クライマー向けギフト",
  "Ski + Snow for Her": "スキーヤー・スノーボーダー向けギフト",
  "Hike Gifts for Her": "ハイカー向けギフト",
  "Veilance Gifts for Her": "ヴェイランス ギフト",
  "Year of the Dragon Capsule": "Year of the Dragonカプセルコレクション",
  "ePE & Fabric Sustainability": "ePE & 生地のサステナビリティ",
  "Men's Footwear": "メンズ フットウェア",
  "Men's Hike": "メンズ ハイキング",
  "Men's Climb": "メンズ クライミング",
  "Men's Run": "メンズ ランニング",
  "Women's Footwear": "ウィメンズ フットウェア",
  "Women's Hike": "ウィメンズ ハイキング",
  "Women's Climb": "ウィメンズ クライミング",
  "Women's Run": "ウィメンズ ランニング",
  "Footwear Innovation": "フットウェア イノベーション",
  "Ski-and-Snowboard": "スキー & スノーボード",
  Touring: "ツーリング",
  Freeride: "フリーライド",
  Resort: "リゾート",
  Trail: "トレイル",
  "Mountain Run": "マウンテンラン",
  Hike: "ハイキング",
  Alpine: "アルパイン",
  Rock: "ロッククライミング",
  Boulder: "ボルダリング",
  Climbing: "クライミング",
  EverydayEvolution: "エブリディ",
  "Micon LiTRIC Avalanche Airbag": "MiCON LiTRIC アバランチ エアバッグ",
  "Stocking Stuffers": "靴下に入れるギフト",
  "Small Gifts": "スモールギフト",
  "Warm Layers": "ウォームレイヤー",
  "US-BlackFriday": "ブラックフライデーベストセラー",
};
